<template>
    <div class="check-box" @click="onClick">
        <div :class="selected ? 'check-item check-item_checked' : 'check-item'">{{ label }}</div>
        <van-icon v-if="icon && selected" class="check-icon" name="success" size="14" color="#ee0a24" />
    </div>
</template>

<script>

export default {
    name: "CheckItem",
    components: {
    },
    inject: {
        CheckItemGroup: {
            default: null
        }
    },
    computed: {
        selected: {
            get() {
                if (this.CheckItemGroup) {
                    if (this.CheckItemGroup.value.length === 0 && (typeof this.name === "undefined" || this.name === null || this.name === "")) {
                        return true;
                    } else {
                        return this.CheckItemGroup.value.indexOf(this.name) > -1;
                    }
                } else {
                    return this.checked;
                }
            },
        }
    },
    props: {
        checked: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
        };
    },
    methods: {
        onClick() {
            if (this.CheckItemGroup) {
                this.CheckItemGroup.updateValue(this.name, !this.selected);
            } else {
                this.$emit("update:checked", this.checked);
            }
            this.$emit('click');
        }
    },
    mounted() {

    }
};
</script>

<style scoped>
.check-box {
    display: flex;
    align-items: center;
}

.check-item {
    color: #323233;
    font-size: 14px;
    padding: 10px;
}

.check-item_checked {
    color: #ee0a24;
}

.check-icon {
    margin-left: auto;
    margin-right: 10px;
}
</style>